@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.sidebar-profile {
  &__profile {
    border-right: 1px solid $gray-color-100;

    width: $sidebar-width;
    height: auto;

    background-color: $white;

    &__avatar {
      height: 40px;
      width: 40px;

      border-radius: 50%;
    }

    &__image {
      object-fit: cover;
    }

    &__user-name {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $neutral-color-900;
    }

    &__email {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $neutral-color-900;
    }
  }

  &__btn {
    &-inner {
      border-radius: $border-radius-lg;
      background-color: $white;
      padding: 8px;

      &--open {
        background-color: $primary-color-50;

        .sidebar-profile__me,
        .sidebar-profile__workspace {
          border: 3px solid $primary-color-50 !important;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $primary-color-50;

        .sidebar-profile__me,
        .sidebar-profile__workspace {
          border: 3px solid $primary-color-50 !important;
        }
      }
    }

    &-wrapper {
      width: 100%;
    }
  }

  &__me,
  &__workspace {
    font-size: 12px;
    border: 3px solid $white;
  }

  &__me {
    transform: translateX(-8px);
  }

  &__chevron {
    cursor: pointer;
    color: $neutral-color-900;
    transition: transform 0.2s ease-in-out;

    &--open {
      transform: rotate(180deg);
    }

    &--disable {
      color: $neutral-color-300;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__popover {
    ::-webkit-scrollbar {
      display: none;
    }
    color: $neutral-color-900;
    background-color: $white;
    bottom: 100%;
    border: 1px solid $neutral-color-100;

    &__add-workspace {
      border: 2px solid $primary;
      background-color: $white;
      width: 30px;
      height: 30px;

      &:hover {
        cursor: pointer;
        background-color: $primary-color-50;
      }
    }

    &__workspaces {
      border-top-right-radius: 16px;

      pointer-events: none;
    }

    &__workspaces-has-permission {
      pointer-events: auto;

      transition: background 0.2s ease-in-out;

      .sidebar-profile__popover__workspaces__settings-icon {
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        cursor: pointer;
        background-color: $primary-color-50;
      }

      &:hover > .sidebar-profile__popover__workspaces__settings-icon {
        transform: rotate(90deg);
        color: $neutral-color-900;
      }
    }
  }

  &__list > ul {
    li {
      &:hover > a {
        color: $primary;
        background-color: $primary-color-50;
      }
      a {
        font-weight: 500;
        color: $neutral-color-900;
        transition:
          background 0.2s ease-in-out,
          color 0.2s ease-in-out;

        &.active {
          color: $primary;
          background-color: $primary-color-50;
        }
      }
    }
  }

  &__avatar {
    font-size: 12px;
    border: 3px solid $white;
  }

  &__btn-wrapper {
    padding: 8px;
  }
}

.vertical-collapsed {
  .sidebar-profile__btn-inner .sidebar-profile__chevron {
    opacity: 0;
  }

  .sidebar-profile__btn-inner {
    padding: 8px 4px;
  }

  .sidebar-profile__btn-wrapper {
    padding: 8px 4px;
  }
}
