@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.sidebar-master-admin-menu-bottom,
.sidebar-menu-bottom {
  width: $sidebar-width;
  transition: width 0.2s ease-in-out;
  border-right: 1px solid $gray-color-100;
}

.vertical-collapsed {
  .sidebar-master-admin-menu-bottom,
  .sidebar-menu-bottom {
    width: $sidebar-collapsed-width;
  }
}
